import Cookies from "js-cookie";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { loginUser } from "../../redux/auth";

const ProtectRouteAdmin = ({ children, ...rest }) => {
  const isLoggedIn = rest?.user?.authorization == "member" ? true : false;
  const dataCookie = Cookies.get("loginUser");
  const dispatch = useDispatch();
  console.log("ress", dataCookie, rest.user);

  React.useMemo(() => {
    if (rest?.user?.email !== dataCookie) {
      dispatch(loginUser(dataCookie, ""));
    }
    if (rest?.user?.email == dataCookie && isLoggedIn) {
      window.location.href = "/#/videolist";
    }
  }, [rest?.user?.email, dataCookie]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          <div className="d-flex justify-content-center">
            <div
              class="spinner-border"
              role="status"
              style={{
                width: "10rem",
                height: "10rem",
                fontSize: "35px",
                color: "#EF60A3",
              }}
            ></div>
          </div>
        ) : (
          children
        )
      }
    />
  );
};

export default ProtectRouteAdmin;
